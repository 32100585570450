import React, { useMemo } from 'react';
import UseAxiosPublic from '../../../hook/UseAxiosPublic';
import { useQuery } from '@tanstack/react-query';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import blogBg from '../../../assets/blogbgFinal.png';

import UseAuth from '../../../hook/UseAuth';

const News = () => {
    const axiosPublic = UseAxiosPublic();
    const { loading } =UseAuth(); // Get the loading state from AuthContext
    const [searchTerm, setSearchTerm] = React.useState('');
    const { data: items = [], refetch } = useQuery({
        queryKey: ['items', searchTerm],
        queryFn: async () => {
            const res = await axiosPublic.get(`/blogs?search=${searchTerm}`);
            return res.data;
        },
        enabled: !loading // Only fetch data when not loading
    });

    const conCateText = (text = "", wordsLimit) => {
        const words = text.split(" ");
        if (words.length <= wordsLimit) {
            return text;
        }
        return words.slice(0, wordsLimit).join(" ") + "...";
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
        refetch();
    };

    const categoryCounts = useMemo(() => {
        const counts = items.reduce((acc, item) => {
            const category = item.category || "Others";
            if (!acc[category]) {
                acc[category] = 0;
            }
            acc[category]++;
            return acc;
        }, {});
        counts["All"] = items.length;
        return counts;
    }, [items]);

    const sortedBlogs = useMemo(() => {
        return [...items].sort((a, b) => new Date(b.Publish_date) - new Date(a.Publish_date));
    }, [items]);

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while waiting for auth or data
    }

    return (
        <div className=''>
            <div>
                <div>
                    <img className='w-full h-[380px] object-cover' src={blogBg} alt='custom website development' />
                </div>
                <Link to='/'>
                    <button
                        className='absolute top-5 right-5 p-3 w-[150px] text-white font-semibold rounded-lg border-2 border-transparent hover:bg-white hover:text-black transition duration-300 blog-button cursor-pointer z-20'
                    >
                        Home
                    </button>
                </Link>
            </div>
           
            <h1 className='text-3xl font-bold text-center py-10 underline-offset-2 underline'>Latest News</h1>
            <div className='max-w-7xl mx-auto flex justify-between gap-6'>
                <div className='max-w-4xl '>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8  '>
                        {items.map((blog, index) => (
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    backgroundColor: '#64748b',
                                    color: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                }}
                                key={index}
                            >
                                <div>
                                    <CardMedia
                                        sx={{ height: 240 }}
                                        image={blog.image}
                                        title={blog.title}
                                    />
                                    <CardContent sx={{ backgroundColor: '#64748b', color: 'white', flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <p className=' md:text-xl'>{blog.title}</p>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <div dangerouslySetInnerHTML={{ __html: conCateText(blog.details, 20) }} />
                                        </Typography>
                                    </CardContent>
                                </div>
                                <div>
                                    <Link to={`/blog/${blog._id}`}>
                                        <button className='btn ml-4 mb-4 rounded-sm'>Read More</button>
                                    </Link>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className='ml-4 '>
                    <p>SEARCH</p>
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearch}
                        className="border-2 p-2"
                    />
                    <div className="py-4 text-xl">
                        <p className="text-base mb-2 mt-4">CATEGORIES</p>
                        <div className="flex justify-between">
                            <p>All</p>
                            <p>({categoryCounts["All"]})</p>
                        </div>
                        <hr />
                        {Object.keys(categoryCounts)
                            .filter((category) => category !== "All")
                            .map((category) => (
                                <div key={category} className="list-none">
                                    <div className=" flex justify-between">
                                        <div>{category}</div>{" "}
                                        <div>({categoryCounts[category]})</div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                    </div>
                    <div>
                        <p className="text-xl font-semibold mb-2 mt-2">RECENT POSTS</p>
                        <div>
                            {sortedBlogs.slice(0, 3).map((blog) => (
                                <div key={blog._id} className="flex items-center mb-4">
                                    <div className="w-20 mr-4">
                                        <img src={blog.image} alt={blog.title} />
                                    </div>
                                    <Link to={`/blog/${blog._id}`}>
                                        <p className="text-base font-semibold">
                                            {blog.title}
                                        </p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
