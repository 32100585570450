import React from 'react';
import img from '../../assets/img.png';

const FreelanceHire = ({scrollToSection,contactRef}) => {
    return (
        <div className='relative max-w-[1616px] h-[400px] mx-auto mt-16 overflow-x-hidden'>
            {/* Black overlay */}
            <div className='absolute inset-0 bg-black opacity-40'></div>
            
            <div className='relative z-10 flex items-center justify-center h-full'>
                <div className='text-center'>
                    <p className=' lg:text-6xl font-bold text-white mb-8'>
                        I'm available for freelance work
                    </p>
                    <button onClick={() => {
                        console.log('Button clicked');
                        scrollToSection(contactRef)
                        }} className='text-white bg-red-500 px-8 py-4 rounded-3xl'>
                        Hire Me Now
                    </button>
                </div>
            </div>
            
            <div className='hidden md:block absolute bottom-0 left-0 z-10'>
                <img src={img} alt='Freelance' className='h-full object-contain' />
            </div>
           
        </div>
    );
};

export default FreelanceHire;
