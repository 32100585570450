import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Blog = () => {
    const [blogs, setBlogs] = useState([]);

    const conCateText = (text, wordsLimit) => {
        const words = text.split("");
        if (words.length <= wordsLimit) {
            return text;
        }
        return words.slice(0, wordsLimit).join(" ") + "...";
    }

    useEffect(() => {
        fetch('https://portbackend-roan.vercel.app/blogs')
            .then(res => res.json())
            .then(data => {
                // Sort blogs by publish_date in descending order
                const sortedBlogs = data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));
                // Get the latest three blogs
                const latestBlogs = sortedBlogs.slice(0, 3);
                setBlogs(latestBlogs);
            });
    }, []);

    return (
        <div className='max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 mt-20 overflow-x-hidden'>
            <div>
                <Helmet>
                    <title>{blogs.title || "Blog"}</title>
                    <meta name="description" content={blogs.meta_description || blogs.description} />
                    <meta name="keywords" content={blogs.keywords} />
                    <meta name="author" content="Top Orion" />
                    <meta property="title" content={blogs.title} />
                </Helmet>
            </div>
            {/* <div className='mx-auto mb-4'>
                <div className='bg-black bg-opacity-50 p-6 rounded-md'>
                    <p className='text-4xl font-bold text-center'>
                        The Role of UX within Mobile Application Development
                    </p>
                    <p className='mt-4 text-lg'>
                        In today’s fast-paced digital landscape, where mobile apps are the backbone of countless industries, the importance of exceptional user experience (UX) cannot be overstated. It’s not just about creating something visually appealing—it’s about ensuring seamless functionality and meaningful engagement.
                        A great example of this is the partnership between TopOrion and Blacksmith Agency. Together, they’ve tackled some of the most challenging UX problems in mobile app development, blending their expertise in design, technology, and marketing. When Mike Moore, a project leader at Blacksmith Agency, collaborated with the TopOrion team on a healthcare app, the results were phenomenal. By combining intuitive design with data-driven insights, they turned a clunky interface into an app users couldn’t put down. Curious about how they make it all happen? Check out Blacksmith Agency’s offering and its innovative approach to UX.
                        One of the standout features of this partnership is how both teams align on user-centric design principles. For instance, Blacksmith’s focus on SEO-driven UX strategies perfectly complements TopOrion's technical expertise. This synergy not only helps users find apps but also ensures they enjoy using them. It’s the kind of teamwork that has led to incredible outcomes for different brands that relied on their combined expertise for app optimization.
                        UX, however, isn’t always smooth sailing. During a recent collaboration, a retail client was struggling with user drop-offs during checkout. The joint effort from TopOrion and Blacksmith tackled the issue head-on by simplifying the process and adding visual cues to guide users. Want to see this kind of magic in action? don’t miss the demo of their custom web and app solutions.
                        In the end, great UX goes beyond aesthetics—it’s about creating experiences that resonate. Partnerships like the one between TopOrion and Blacksmith Agency highlight how collaboration can amplify impact, delivering apps that not only meet but exceed user expectations. After all, as Mike says, “A perfect UX isn’t built alone—it’s crafted with the right partners.”

                    </p>
                </div>
            </div> */}

            <div className='text-center mb-20'>
                <p className='text-2xl font-bold'>News || Blog</p>
                <p className='text-4xl font-bold'>Latest From Blog</p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
                {blogs.map((blog, index) => (
                    <Card
                        sx={{
                            maxWidth: 345,
                            backgroundColor: '#64748b',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                        key={index}
                    >
                        <div>
                            <CardMedia
                                sx={{ height: 240 }}
                                image={blog.image}
                                title={blog.title}
                            />
                            <CardContent sx={{ backgroundColor: '#64748b', color: 'white', flexGrow: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {blog.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <div dangerouslySetInnerHTML={{ __html: conCateText(blog.details, 10) }} />
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <Link to={`/blog/${blog._id}`}>
                                <button className='btn ml-4 mb-4 rounded-sm'>Read More</button>
                            </Link>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Blog;
